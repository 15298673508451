<template>
    <div></div>
</template>
<script>
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");
require("echarts/lib/component/grid");
require("echarts/lib/component/dataZoom");

export default {
    data() {
        return {
            showNoData: false,
            showYName: '',
            selected: '',
            defoption: {
                color: [
                    "#00D2EF",
                    "#FF9E17",
                    "#4962FC",
                    "#f5a623",
                    "#dd3ee5",
                    "#12e78c",
                    "#fe8104",
                    "#01C2F9",
                    "#F4CB29",
                    "#FD9E06",
                ],
                // backgroundColor:'#033c86',
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                    },
                },
                legend: {
                    left: "center",
                    top: 5,
                    icon: "circle",
                    itemWidth: 8,
                    data: [],
                    selected: null,
                    textStyle: {
                        color: "#555",
                        fontSize: 14,
                    },
                },
                grid: {
                    top: "20%",
                    left: "40",
                    right: "10",
                    bottom: "40",
                },
                xAxis: [
                    {
                        type: "category",
                        axisLabel: {
                            //坐标轴刻度标签的相关设置
                            textStyle: {
                                color: "#555",
                                margin: 15,
                            },
                            formatter: function (value) {
                                var t = value.split(' ')[0];
                                return t;
                            },
                            show: true,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#555",
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(32, 254, 254,.1)",
                            },
                        },
                        axisTick: {
                            show: true,
                        },
                        zlevel: 1,
                        data: [],
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "",
                        nameGap: 8,
                        minInterval: 10,
                        nameTextStyle: {
                            color: "#555",
                            fontSize: 12,
                        },
                        axisLabel: {
                            //坐标轴刻度标签的相关设置
                            textStyle: {
                                color: "#555",
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#555",
                            },
                        },
                        axisTick: {
                            show: true,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(32, 254, 254,.1)",
                            },
                        },
                        min: (value) => {
                            return value.min < 0 ? Math.floor(value.min - (this.deviceType == 'GNSS' ? 50 : 10)) : 0;
                        },
                        max: (value) => {
                            return Math.ceil(value.max + (this.deviceType == 'GNSS' ? 50 : 10));
                        }
                    },
                ],
                series: [],
            },
        };
    },
    props: {
        deviceType: {
            type: String,
        },
        yname: {
            type: String,
        },
        minInterval: {
            type: Number,
            default: 1,
        },
        option: {
            type: Object,
        },
        data: {
            type: Object,
        },
    },
    methods: {
        initChart() {
            if (this.data.series.length == 2) {
                this.initTwoRowChart();
            } else {
                this.initOneRowChart();
            }
        },
        initOneRowChart() {
            var that = this;
            this.defoption.yAxis[0].name = this.showYName;
            this.defoption.yAxis[0].minInterval = this.minInterval;
            var chartOpt = Object.assign(this.defoption, this.option);
            chartOpt.xAxis[0].data = this.data.xAxis;

            var series = this.data.series;
            chartOpt.legend.data = [];
            chartOpt.series = [];
            for (let index = 0; index < series.length; index++) {
                const element = series[index];
                chartOpt.legend.data.push({
                    name: element.name
                });
                let colorIndex = index;
                if (colorIndex >= chartOpt.color.length) {
                    //取模
                    colorIndex = colorIndex % chartOpt.color.length
                }
                chartOpt.series.push({
                    name: element.name,
                    type: "line",
                    smooth: true,
                    symbolSize: 0,
                    areaStyle: that.deviceType != 'GNSS' ? {
                        //区域填充样式
                        normal: {
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    { offset: 0, color: chartOpt.color[colorIndex] },
                                    { offset: 1, color: "rgba(0,0,0,0)" },
                                ],
                                false
                            ),
                            shadowColor: "rgba(53,142,215, 0.9)", //阴影颜色
                            shadowBlur: 20,
                        },
                    } : {
                        normal: {
                            color: 'transparent'
                        }
                    },
                    data: element.data,
                });
            }

            if (that.selected) {
                chartOpt.legend.selected = JSON.parse(JSON.stringify(that.selected));
            } else {
                chartOpt.legend.selected = null;
            }
            console.log(JSON.stringify(chartOpt.legend));

            var chartObj = echarts.init(document.getElementById(this.$el.id));
            chartObj.clear();
            chartObj.setOption(chartOpt);
            chartObj.on('legendselectchanged', function (params) {
                var selected = params.selected;
                var selectedArr = [];
                for (var key in selected) {
                    selectedArr.push({
                        yname: key,
                        selected: selected[key]
                    })
                }
                if (selectedArr.filter(item => item.selected).length > 0) {
                    let yname = (selectedArr.filter(item => item.selected))[0].yname;
                    var selectY = that.data.list.filter(item => item.name == yname);
                    if (selectY.length > 0) {
                        that.showYName = that.deviceType == 'GNSS' ? '累计位移(' + selectY[0].unit + ')' : selectY[0].name + '(' + selectY[0].unit + ')';
                    } else {
                        that.showYName = yname;
                    }
                }
                that.selected = params.selected;
                chartObj.dispose();
                that.initChart();
            });
        },
        initTwoRowChart() {
            //当折线图表格有且存在2个传感器数据的时候，折线图设置成双坐标显示
            var that = this;
            var chartOpt = Object.assign(this.defoption, this.option);
            chartOpt.xAxis[0].data = this.data.xAxis;
            chartOpt.grid.right = 40;


            chartOpt.yAxis[0].minInterval = this.minInterval;
            chartOpt.yAxis.push({
                type: "value",
                name: "",
                nameGap: 8,
                minInterval: 10,
                nameTextStyle: {
                    color: "#555",
                    fontSize: 12,
                },
                axisLabel: {
                    //坐标轴刻度标签的相关设置
                    textStyle: {
                        color: "#555",
                    },
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#555",
                    },
                },
                axisTick: {
                    show: true,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: "rgba(32, 254, 254,.1)",
                    },
                },
                min: (value) => {
                    return value.min < 0 ? Math.floor(value.min - (this.deviceType == 'GNSS' ? 50 : 10)) : 0;
                },
                max: (value) => {
                    return Math.ceil(value.max + (this.deviceType == 'GNSS' ? 50 : 10));
                }
            })

            var series = this.data.series;
            chartOpt.legend.data = [];
            chartOpt.series = [];
            console.log(series);
            for (let index = 0; index < series.length; index++) {
                const element = series[index];
                chartOpt.legend.data.push({
                    name: element.name
                });
                chartOpt.yAxis[index].name = element.name;

                let colorIndex = index;
                if (colorIndex >= chartOpt.color.length) {
                    //取模
                    colorIndex = colorIndex % chartOpt.color.length
                }
                chartOpt.series.push({
                    name: element.name,
                    yAxisIndex: index,
                    type: "line",
                    smooth: true,
                    symbolSize: 0,
                    areaStyle: that.deviceType != 'GNSS' ? {
                        //区域填充样式
                        normal: {
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    { offset: 0, color: chartOpt.color[colorIndex] },
                                    { offset: 1, color: "rgba(0,0,0,0)" },
                                ],
                                false
                            ),
                            shadowColor: "rgba(53,142,215, 0.9)", //阴影颜色
                            shadowBlur: 20,
                        },
                    } : {
                        normal: {
                            color: 'transparent'
                        }
                    },
                    data: element.data,
                });
            }

            if (that.selected) {
                chartOpt.legend.selected = JSON.parse(JSON.stringify(that.selected));
            } else {
                chartOpt.legend.selected = null;
            }
            console.log(JSON.stringify(chartOpt.legend));

            var chartObj = echarts.init(document.getElementById(this.$el.id));
            chartObj.clear();
            chartObj.setOption(chartOpt);
            chartObj.on('legendselectchanged', function (params) {
                var selected = params.selected;
                var selectedArr = [];
                for (var key in selected) {
                    selectedArr.push({
                        yname: key,
                        selected: selected[key]
                    })
                }
                if (selectedArr.filter(item => item.selected).length > 0) {
                    let yname = (selectedArr.filter(item => item.selected))[0].yname;
                    var selectY = that.data.list.filter(item => item.name == yname);
                    if (selectY.length > 0) {
                        that.showYName = that.deviceType == 'GNSS' ? '累计位移(' + selectY[0].unit + ')' : selectY[0].name + '(' + selectY[0].unit + ')';
                    } else {
                        that.showYName = yname;
                    }
                }
                that.selected = params.selected;
                chartObj.dispose();
                that.initChart();
            });
        }
    },
    mounted() {
        setTimeout(() => {
            this.showYName = this.deviceType == 'GNSS' ? '累计位移(mm)' : this.yname;
            this.initChart();
        }, 500);
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.showYName = this.deviceType == 'GNSS' ? '累计位移(mm)' : this.yname;
                this.selected = '';
                echarts.init(document.getElementById(this.$el.id)).dispose();
                this.initChart();
            },
        },
    },
};
</script>