<template>
    <div></div>
</template>
<script>
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");
require("echarts/lib/component/grid");
require("echarts/lib/component/dataZoom");

export default {
    data() {
        return {
            showNoData: false,
            defoption: {
                color: [
                    "#00D2EF",
                    "#FF9E17",
                    "#4962FC",
                    "#f5a623",
                    "#dd3ee5",
                    "#12e78c",
                    "#fe8104",
                    "#01C2F9",
                    "#F4CB29",
                    "#FD9E06",
                ],
                // backgroundColor:'#033c86',
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                    },
                },
                legend: {
                    left: 'center',
                    top: 5,
                    icon: "circle",
                    itemWidth: 8,
                    data: [],
                    textStyle: {
                        color: "#555",
                        fontSize: 11,
                    },
                },
                grid: {
                    top: "20%",
                    left: "40",
                    right: "50",
                    bottom: "20",
                },
                xAxis: [
                    {
                        type: "category",
                        axisLabel: {
                            //坐标轴刻度标签的相关设置
                            textStyle: {
                                color: "#555",
                                margin: 15,
                            },
                            formatter:function(value){
                                var t=value.split(' ')[0];
                                return  t;
                            },
                            show: true,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#555",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(32, 254, 254,.1)",
                            },
                        },
                        axisTick: {
                            show: true,
                        },
                        data: [],
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "",
                        nameGap: 8,
                        minInterval: 10,
                        nameTextStyle: {
                            color: "#555",
                            fontSize: 12,
                        },
                        axisLabel: {
                            textStyle: {
                                color: "#555",
                            },
                        },
                        axisLine: {
                            show:true,
                            lineStyle: {
                                color: "#555",
                            },
                        },
                        axisTick: {
                            show: true,
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(32, 254, 254,.1)",
                            },
                        },
                        min:(value)=>{
                            return value.min-10 >0 ? Math.floor(value.min-10) : 0;
                        },
                        max:(value)=>{
                            return Math.floor(value.max+10);
                        }
                    },
                    {
                        type: 'value',
                        name: '累计雨量',
                        nameTextStyle: {
                            color: "#555",
                            fontSize: 12,
                        },
                        axisLabel: {
                            textStyle: {
                                color: "#555",
                            },
                        },
                        axisLine: {
                            show:true,
                            lineStyle: {
                                color: "#555",
                            },
                        },
                        axisTick: {
                            show: true,
                        },
                        splitLine: {
                            show: false,
                        },
                        min:(value)=>{
                            return value.min < 0 ? Math.floor(value.min-10) : 0;
                        },
                        max:(value)=>{
                            return Math.ceil(value.max+10);
                        }
                    }
                ],
                series: [],
            },
        };
    },
    props: {
        yname: {
            type: String,
        },
        minInterval: {
            type: Number,
            default: 1,
        },
        option: {
            type: Object,
        },
        data: {
            type: Object,
        },
    },
    methods: {
        initChart() {
            this.defoption.yAxis[0].name = this.yname;
            this.defoption.yAxis[0].minInterval = this.minInterval;
            var chartOpt = Object.assign(this.defoption, this.option);
            chartOpt.xAxis[0].data = this.data.xAxis;

            var series = this.data.series;
            chartOpt.legend.data = [];
            chartOpt.series = [];
            for (let index = 0; index < series.length; index++) {
                const element = series[index];
                chartOpt.legend.data.push(element.name);
                if(element.type=='line'){
                    chartOpt.series.push({
                        name: element.name,
                        yAxisIndex: 1,
                        type: "line",
                        smooth: true,
                        symbolSize: 0,
                        barMinWidth: 8,
                        data: element.data,
                    });
                }else{
                    chartOpt.series.push({
                        name: element.name,
                        type: "bar",
                        smooth: true,
                        symbolSize: 0,
                        barMinWidth: 8,
                        areaStyle: {
                            //区域填充样式
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        { offset: 0, color: chartOpt.color[index] },
                                        { offset: 1, color: "rgba(0,0,0,0)" },
                                    ],
                                    false
                                ),
                                shadowColor: "rgba(53,142,215, 0.9)", //阴影颜色
                                shadowBlur: 20,
                            },
                        },
                        data: element.data,
                    });
                }
                
            }

            var chartObj = echarts.init(document.getElementById(this.$el.id));
            chartObj.clear();
            chartObj.setOption(chartOpt);
        },
    },
    mounted() {
        setTimeout(() => {
            this.initChart();
        }, 500);
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.initChart();
            },
        },
    },
};
</script>