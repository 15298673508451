<template>
    <div></div>
</template>
<script>
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");
require("echarts/lib/component/grid");
require("echarts/lib/component/dataZoom");

export default {
    data() {
        return {
            showNoData: false,
            defoption: {
                color: [
                    "#00D2EF",
                    "#FF9E17",
                    "#4962FC",
                    "#f5a623",
                    "#dd3ee5",
                    "#12e78c",
                    "#fe8104",
                    "#01C2F9",
                    "#F4CB29",
                    "#FD9E06",
                ],
                // backgroundColor:'#033c86',
                tooltip: {
                    trigger: "axis",
                    position: function (point) {
                        return [point[0], point[1]];
                    },
                    axisPointer: {
                        type: "shadow",
                    },
                },
                legend: {
                    show: false,
                    right: 50,
                    top: 10,
                    orient: "vertical",
                    icon: "circle",
                    itemWidth: 8,
                    data: [],
                    textStyle: {
                        color: "#fff",
                        fontSize: 14,
                    },
                },
                grid: {
                    top: "40",
                    left: "40",
                    right: "100",
                    bottom: "60",
                },
                xAxis: [
                    {
                        type: "value",
                        position: "top",
                        name: "偏移量(度)",
                        boundaryGap: [0, 0.01],
                        axisLabel: {
                            //坐标轴刻度标签的相关设置
                            textStyle: {
                                color: "#555",
                                margin: 15,
                            },
                            show: true,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#555",
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(32, 254, 254,.1)",
                            },
                        },
                        axisTick: {
                            show: true,
                        },
                        interval:10,
                        max:90,
                    },
                ],
                yAxis: [
                    {
                        type: "category",
                        name: "高程(m)",
                        inverse: true,
                        nameTextStyle: {
                            color: "#555",
                            fontSize: 12,
                        },
                        axisLabel: {
                            //坐标轴刻度标签的相关设置
                            textStyle: {
                                color: "#555",
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#555",
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(32, 254, 254,.1)",
                            },
                        },
                        axisTick: {
                            show: true,
                        },
                        data: ["10", "15", "20", "25"],
                    },
                ],
                series: [],
            },
        };
    },
    props: {
        yname: {
            type: String,
        },
        minInterval: {
            type: Number,
            default: 1,
        },
        option: {
            type: Object,
        },
        data: {
            type: Object,
        },
    },
    methods: {
        initChart() {
            var chartOpt = Object.assign(this.defoption, this.option);
            chartOpt.legend.data = [];
            chartOpt.series = [];
            var ydata = this.data.series.depth;
            chartOpt.yAxis[0].data = ydata;
            var series = this.data.series.cxdata;
            for (let index = 0; index < series.length; index++) {
                const element = series[index];
                chartOpt.legend.data.push(element.time);
                var seriesdata = element.x;
                if(element.x.length>4){
                    var ydata = [];
                    for (let i = 0; i < element.x.length; i++) {
                        xdata.push(10+(i*5)+"");
                    }
                    chartOpt.yAxis[0].data = ydata;
                }
                chartOpt.series.push({
                    name: element.time,
                    type: "line",
                    smooth: true,
                    symbol:'circle',//拐点设置为实心
                    symbolSize:15,//拐点大小
                    data: seriesdata,
                });
            }
            var chartObj = echarts.init(document.getElementById(this.$el.id));
            chartObj.clear();
            chartObj.setOption(chartOpt);
        },
    },
    mounted() {
        setTimeout(() => {
            if(this.data && this.data.series){
                this.initChart();
            }
        }, 500);
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.initChart();
            },
        },
    },
};
</script>